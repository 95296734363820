import React from 'react';
import ListSubheader from "@mui/material/ListSubheader";
import NavItem from "./NavItem";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";

const NavList = ({navTitle, navList}) => {
	return (
		<List subheader={navTitle && <ListSubheader sx={{fontSize: "18px!important"}} >{navTitle}</ListSubheader>}>
			{navList.map((item, index) => (
				<NavItem item={item} key={index}/>
			))}
		</List>
	);
};

export default NavList;