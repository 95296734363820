import React from "react";

import LoginLayout from "@ui/layout/LoginLayout";
import Paper from "@mui/material/Paper";

import LoginForm from "../components/Profile/LoginForm";


function LoginPage() {
	return (
		<LoginLayout>
			<Paper elevation={4} sx={{p:6}}>
				<LoginForm/>
			</Paper>
		</LoginLayout>
	);
}
export default  LoginPage;
