import React, {useEffect, useState} from "react";

import TextField from "@mui/material/TextField";
import PageTitle from "@ui/PageTitle";
import DrawerLayout from "@ui/layout/DrawerLayout";

import PatientList from "@Patient/List/PatientList";

import useTranslate from "@hooks/useTranslate";
import patientGetAllFilteredRequest from "@api/patient/getAllFiltered";

import translates from "./translates";


const PatientListPage = () => {
	
	const {pageTitle} = useTranslate({translates});
	const [lastNameFilter, setLastNameFilter] = useState("");
	const [data, setData] = useState([]);
	
	const getFilteredPatients = async ()=>{
		const result = await patientGetAllFilteredRequest(lastNameFilter);
		setData(result.data)
	};
	
	useEffect(()=>{
			getFilteredPatients().catch(e=>console.error(e));
	},[lastNameFilter]);
	
	return (
		<DrawerLayout>
			<PageTitle text={pageTitle} sx={{mb:4}}/>
			<TextField label="Фамилия пациента" variant="outlined"
			           value={lastNameFilter}
			           onChange={(event) => {
				           setLastNameFilter(event.target.value);
			           }}
			/>
			<PatientList patientList={data}/>
		</DrawerLayout>
	);
};
export default PatientListPage;