import React, {} from 'react';

import { SxProps } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

import ProcessingListHome from "@components/Processing/ListHome/ProcessingListHome";

import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


interface inter {
	sx:SxProps
}

const ProcessingBlock = ({sx}:inter) => {
	
	const {blockTitle} = useTranslate({translates});
	return (
		<Card sx={sx}>
			<CardHeader title={blockTitle}/>
			<Divider/>
			<CardContent>
				<ProcessingListHome/>
			</CardContent>
		</Card>
	);
};
export default ProcessingBlock;