import React from "react";

import LoginLayout from "@ui/layout/LoginLayout";
import Paper from "@mui/material/Paper";

import RegisterForm from "../components/Profile/RegisterForm";


const RegisterPage = () => {
	return (
		<LoginLayout>
			<Paper elevation={4} sx={{p:6}}>
			<RegisterForm/>
			</Paper>
		</LoginLayout>
	);
};
export default RegisterPage;
