import React, {} from 'react';

import {Line} from "@react-three/drei";

//
// <axesHelper args={[256/2]} /> unable to make this component wanted color
const MainAxes = () => {
	return (
		<>
			<Line points={[[0,0,0],[0,0,100]]} color="white" lineWidth={1}/>
			<Line points={[[0,0,0],[100,0,0]]} color="white" lineWidth={1}/>
			<Line points={[[0,0,0],[0,100,0]]} color="white" lineWidth={1}/>
			</>
	);
};
export default MainAxes;