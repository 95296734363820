const translates = {
	en:{
		formTitle:'Authorization',
		submitButtonTitle:'Enter',
		submitButtonProcessTitle:'loading',
		or:'or',
		register:'register',
		fields:{
			username:'Username',
			password:'Password',
		}
	},
	ru:{
		formTitle:'Авторизация',
		submitButtonTitle:'Войти',
		submitButtonProcessTitle:'загрузка',
		or:'или',
		register:'зарегистрироваться',
		fields:{
			username:'Имя пользователя',
			password:'Пароль',
		}
	}
};
export default translates;