import React, {Suspense, useEffect, useRef} from "react";
import {Canvas} from "@react-three/fiber";
import {useSelector} from "react-redux";
import {MapControls, Stats} from "@react-three/drei";
// import {OrbitControls} from "@react-three/drei";


import Pointer from "../Pointer";

import useGltfCanvas from "./useGLTFCanvas";
import RoiPoints from "./components/RoiPoints";
import MainAxes from "@components/MainAxes";


const GLTFCanvas = ({ node, synth, dim }) => {
	
	const groupRef = useRef();
	
	const { onWheel, updateDrawRange, click, handleKeyDown, handleKeyUp} = useGltfCanvas({ node, synth, dim });
	const { activeIndex, pointPos, showSynth, zoom } = useSelector((state) => state.gltf);

	// to show synth tomograms OVER the orig tomograms
	const getSynthPosition = ()=>{
		return [
			dim.name === 'x' ? 0.2 : 0,
			dim.name === 'y' ? 0.2 : 0,
			dim.name === 'z' ? 0.2 : 0
		];
	};
	
	useEffect(() => {
		console.log(pointPos)
		console.log(activeIndex)
		updateDrawRange(node);
	}, [pointPos, node, synth, activeIndex]);
	
	useEffect(() => {
		
		updateDrawRange(node);
		
		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keyup', handleKeyUp);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.addEventListener('keyup', handleKeyUp);
		}
	}, []);
	
	return (
		<Canvas camera={{...dim.camera, zoom:zoom[dim.name]}} onWheel={onWheel} style={{backgroundColor: "#000000"}}>
			<Suspense fallback={null}>
				<group ref={groupRef} dispose={<div>loading...</div>}>
					<primitive object={node} onClick={click} />
					{synth !==null && showSynth && <primitive object={synth} position={getSynthPosition()}/>}
				</group>
				
				<RoiPoints/>
				
				<Pointer pointPos={pointPos} dim={dim}/>
				{/*<Text color="red" anchorX="center" anchorY="middle" fontSize={56} >{node.userData.dim}</Text>*/}
				{/*<OrbitControls enableZoom={false} enableRotate={true}/>*/}
				<MapControls screenSpacePanning={true} enableDamping={false} enableRotate={false} enableZoom={false}/>
				<Stats />
				
				<MainAxes/>
				<axesHelper args={[256/2]} position={Object.values(pointPos)} />
				<ambientLight/>
				
			</Suspense>
		</Canvas>
	);
};
export default GLTFCanvas;
