import React, { useEffect } from "react";
import { useSelector} from "react-redux";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import GLTFCanvas from "../GLTFCanvas/GLTFCanvas";
import CanvasVolume from "../CanvasVolume/CanvasVolume";
import GLTFCanvasWrapper from "../GLTFCanvasWrapper/GLTFCanvasWrapper";

import useGLTFCanvasGrid from "./useGLTFCanvasGrid";

import dims from "./dims";


const GLTFCanvasGrid = ({_archive}) => {
	
	const {loadGltf} = useGLTFCanvasGrid();
	
	const { scene3DChildren, origVisible, synthChildren,origChildren, synthVisible, gltfReady, zoom } = useSelector((state) => state.gltf);
	
	useEffect(() => {
		loadGltf(_archive)
			.catch(error=>console.log(error))
	}, []);
	
	return (
		<>
			{!gltfReady
				? <Stack alignItems={'center'} justifyContent={'center'} sx={{height: "100vh", width:'100%'}}>
					<CircularProgress size={40} sx={{ mb:2 }} />
					подготовка изображения...
				</Stack>
				: (
					<Box sx={{
						height:"100vh",
						display:'grid',
						gridTemplate:"repeat(2, 1fr) / repeat(2, 1fr)",
						gridGap: "1px",
						backgroundColor: "white",
					}}>
						
						{ scene3DChildren.length !==0
							? <CanvasVolume archive={_archive}/>
							: <Box/>
						}
						
						{dims.map((dim, i)=>
							<GLTFCanvasWrapper dim={dim} key={i}>
								<GLTFCanvas
									node={origChildren[1][origChildren[0].indexOf("MyNode_orig_"+dim.name)][origVisible]}
									synth={synthChildren[1][synthChildren[0].indexOf("MyNode_synth_"+dim.name)][synthVisible]}
									dim={dim}
								/>
							</GLTFCanvasWrapper>
						)}
					
					</Box>
				)
			}
			
		</>
	);
};
export default GLTFCanvasGrid;
