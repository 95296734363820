import React, {} from 'react';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';

import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CustomInput from "@ui/form/CustomInput/CustomInput";

import routes from "../../../routes";

import useRegisterForm from "./useRegisterForm";
import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const RegisterForm = () => {
	
	const {onSubmit, fields, initialValues, validate} = useRegisterForm();
	
	const {formTitle, submitButtonTitle, submitButtonProcessTitle, or, loginLinkTitle, fields:_fields} = useTranslate({translates});
	return (
		<Formik {...{initialValues, validate, onSubmit}} validateOnChange={false} validateOnBlur={false}>
			{({values, errors, handleChange, handleSubmit, isSubmitting, status, setFieldValue}) => (
				<form onSubmit={handleSubmit} autoComplete="off" style={{width: 280}}>
					<Typography component="h5" variant="h5" sx={{textAlign: "center"}}>{formTitle}</Typography>
					<Stack spacing={1.5} sx={{mt: 2}}>
						{fields.map((field, i) => (
							<CustomInput size="small" {...{field:{...field, label:_fields[field.name]}, values, errors, handleChange, setFieldValue}} key={i}/>
						))}
					</Stack>
					{!!status && <Alert severity="error" sx={{mt: 2}}>{status}</Alert>}
					<Button variant="contained" type={'submit'} disabled={isSubmitting} sx={{width: "100%", mt: 2}}>{isSubmitting ? `${submitButtonProcessTitle}...` : submitButtonTitle}</Button>
					<Stack spacing={2} alignItems="center" sx={{mt: 2}}>
						<div>{or}</div>
						<Link to={routes.login}>{loginLinkTitle}</Link>
					</Stack>
				</form>
			)}
		</Formik>
	);
};
export default RegisterForm;