import {useDispatch, useSelector} from "react-redux";
// import {Vector3} from "three";

// import {activeTmSliceIndexesUpdateRedux, realSliceUpdateAllPerspectivesIndexRedux} from "@redux/tomograms/actions";
import {commentsSetRedux} from "@redux/comment/actions";
import {roiPointActiveSetRedux} from "@redux/ROIPoint/actions";
// import {rayCastHelperSetRedux, rayCastSetRedux} from "@redux/rayCast/actions";

import roiGetCommentsRequest from "@api/roi/getComments";
import roiDeleteRequest from "@api/roi/delete";
import useRoiPoint from "@hooks/useRoiPoint";
import useTranslate from "@hooks/useTranslate";

import translates from "./translates";
import {activeIndexSetRedux, pointPosSetRedux} from "@redux/gltf/actions";


const isObjectsEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

const useGltfROIPointItem = ({point}) => {
	
	const dispatch = useDispatch();
	const {deletePoint} = useRoiPoint();
	const {roiDeleteConfirm} = useTranslate({translates});
	const {activeTmSliceIndexes} = useSelector((state) => state.tomograms);
	
	const isActiveSlice = () => {
		const tmData = JSON.parse(point.note.split(';').at(-1));
		return isObjectsEqual(tmData, activeTmSliceIndexes);
	};
	
	const goToPoint = (coords) => {
		// console.log(coords)
		dispatch(activeIndexSetRedux({
			x:Math.ceil(coords.x) + (256/2),
			y:Math.ceil(coords.y) + (256/2),
			z:Math.ceil(coords.z) + (256/2),
		}));

		dispatch(pointPosSetRedux({
			x: Math.ceil(coords.x),
			y: Math.ceil(coords.y),
			z: Math.ceil(coords.z)
		}))
		
		// real.forEach((tm, i) => {
		// 	dispatch(activeTmSliceIndexesUpdateRedux({...coords}));
		// 	dispatch(realSliceUpdateAllPerspectivesIndexRedux({index: i, data: {...coords}}));
		// });
	};
	
	const handleDeleteClick = async (e) => {
		e.stopPropagation();
		if (window.confirm(roiDeleteConfirm)){
			const {isSuccess} = await roiDeleteRequest(point.id);
			isSuccess && deletePoint(point.id);
		}
	};
	
	const handleItemClick = async (e) => {
		e.stopPropagation();
		dispatch(roiPointActiveSetRedux(point.id));
		// console.log(JSON.parse(point.note.split(';').at(0)))
		goToPoint(JSON.parse(point.note.split(';').at(0)));
		
		// dispatch(rayCastSetRedux(new Vector3().copy({...JSON.parse(point.note.split(';').at(0))})));
		// dispatch(rayCastHelperSetRedux(new Vector3().copy({...JSON.parse(point.note.split(';').at(0))})));
		
		const {data} = await roiGetCommentsRequest(point.id);
		dispatch(commentsSetRedux(data))
	};
	
	const isActive = isActiveSlice();
	
	return {
		isActiveSlice,
		goToPoint,
		handleDeleteClick,
		handleItemClick,
		isActive,
	};
};
export default useGltfROIPointItem;