import * as React from 'react';
import {useNavigate} from "react-router-dom";

import Typography from '@mui/material/Typography';
import AdbIcon from '@mui/icons-material/Adb';

import routes from "../../../../routes";
import Box from "@mui/material/Box";

// TODO: no huge difference between adaptive versions of logo (remove one it or make difference)
const HeaderLogo = ({sx={}}) => {
	
	const navigate = useNavigate();
	
	const handleHomeClick = async () => {
		navigate(routes.mainMenu)
	};
	
	return (
		<Box sx={{display: 'flex', ...sx}}>
			<AdbIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/>
			<Typography
				variant="h6"
				noWrap
				onClick={handleHomeClick}
				sx={{
					cursor: 'pointer',
					mr: 2,
					display: {xs: 'none', md: 'flex'},
					fontFamily: 'monospace',
					fontWeight: 700,
					letterSpacing: '.1rem',
					color: 'inherit',
					textDecoration: 'none',
				}}
			>
				EpilepsyDetect
			</Typography>
			
			<AdbIcon sx={{display: {xs: 'flex', md: 'none'}, mr: 1}}/>
			<Typography
				variant="h5"
				noWrap
				onClick={handleHomeClick}
				sx={{
					cursor: 'pointer',
					mr: 2,
					display: {xs: 'flex', md: 'none'},
					fontFamily: 'monospace',
					fontWeight: 700,
					letterSpacing: '.1rem',
					color: 'inherit',
					textDecoration: 'none',
				}}
			>
				EpilepsyDetect
			</Typography>
		</Box>
	);
}

export default HeaderLogo;