import React, {} from 'react';

import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

import FavoriteListHome from "@components/Favorite/ListHome/FavoriteListHome";

import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const FavoriteBlock = ({sx}:any) => {
	
	const {blockTitle} = useTranslate({translates});
	
	return (
		<Card sx={sx}>
			<CardHeader title={blockTitle}/>
			<Divider/>
			<CardContent>
			  <FavoriteListHome />
			</CardContent>
		</Card>
	);
};
export default FavoriteBlock;