import React, {} from "react";

import ErrorLoading from "@ui/ErrorLoading";
import ErrorStatus from "@ui/ErrorStatus";
import DrawerLayout from "@ui/layout/DrawerLayout";

import PatientSingleMain from "./components/PatientSingleMain";

import usePatientSingle from "./usePatientSingle";


const PatientSinglePage = () => {
	
	const {patient, patientRequestError, loading, tryAgain, patientCodeError, patientStatusError} = usePatientSingle();
	
	return (
		<DrawerLayout>
			
			{loading && <div>loading...</div>}
			
			{patientRequestError && !loading && (<ErrorLoading message={patientRequestError} action={tryAgain}/>)}
			
			{patientCodeError && (<ErrorStatus code={patientCodeError} status={patientStatusError}/>)}
			
			{patient !== null && (<PatientSingleMain/>)}
		
		</DrawerLayout>
	);
};
export default PatientSinglePage;
