import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import {Store} from "@redux/store";

import './localStore/init';
import './idbStore/init';

import "./index.css";

import App from './App';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  /*<React.StrictMode>*/
    <Provider store={Store}>
      <App />
    </Provider>
  /*</React.StrictMode>*/
);