import React from 'react';


const useMyPatientListPage = () => {
	
	const [modalIsOpen, setModalIsOpen] = React.useState(false);
	
	const handleSpeedDialClick = () => setModalIsOpen(true);
	
	return {
		modalIsOpen,
		setModalIsOpen,
		handleSpeedDialClick,
	};
};

export default useMyPatientListPage;