import {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import dateFormat from "dateformat";

import {processingByDataIdDeleteRedux, processingByDataIdUpdateRedux} from "@redux/processing/actions";
import {patientDataSetRedux} from "@redux/patient/actions";
import processingDeleteRequest from "@api/processing/delete";
import patientGetDataRequest from "@api/patient/getData";
import {PROCESSING_STATES, PROCESSINGS_ROOT} from "@api/constants";
import {buildUrl} from "@api/settings";


const dateMask = "mmmm dS, yyyy, HH:MM";

const useProcessingItem = ({processing}) => {
	
	const navigate = useNavigate();
	const {patientId} = useParams();
	const dispatch = useDispatch();
	
	const formattedDate = `${dateFormat(processing.create_date, dateMask)}`;
	const isProcessEnded = (status) => status !== PROCESSING_STATES.progress && status !== PROCESSING_STATES.untouched && status !== PROCESSING_STATES.convert;
	
	const handleButtonDeleteClick = async () => {
		await processingDeleteRequest(processing.id);
		dispatch(processingByDataIdDeleteRedux({dataId:processing.raw_data_id, processingId:processing.id}))
	};
	
	const handleEyeClick = ()=>{
		navigate(`/patients/${patientId}/inspection/${processing.synthetic_data.id}/processing/${processing.synthetic_data.processing_id}/gltf/`)
	};
	
	const handleSSEMessage = useCallback(async (newProcessing) => {
		const {id, raw_data_id, status} = newProcessing;
		dispatch(processingByDataIdUpdateRedux({dataId:raw_data_id, processingId:id, data:newProcessing}));
		
		if (isProcessEnded(status)){
			const result = await patientGetDataRequest(patientId);
			result.isSuccess && dispatch(patientDataSetRedux(result.data))
		}
	},[dispatch,patientId]);
	
	useEffect(()=>{
		if (!isProcessEnded(processing.status)){
			let eventSource = new EventSource(buildUrl(`/${PROCESSINGS_ROOT}/${processing.id}/sse`));
			
			eventSource.onmessage = async (event) => {
				
				const parsedData = JSON.parse(event.data);
				await handleSSEMessage(parsedData);
				parsedData.status === PROCESSING_STATES.finished || parsedData.status === PROCESSING_STATES.failed && eventSource.close();
			};
			return (()=>{
				eventSource.close();
			})
		}
	},[processing.id, processing.status, handleSSEMessage]);
	return {
		handleButtonDeleteClick,
		handleEyeClick,
		formattedDate,
	};
};
export default useProcessingItem;