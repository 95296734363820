import React from 'react';

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import useTranslate from "@hooks/useTranslate";

import useRoiPointListHead from "./useRoiPointListHead";

import translates from "./translates";


const InspectionRoiPointListHead = ({isArchiveLoading}) => {
	
	const {buttonTitle, title} = useTranslate({translates});
	const {handleButtonClick} = useRoiPointListHead();
	
	// const {real} = useSelector((state) => state.tomograms);
	
	return (
		<Stack direction={'row'} alignItems="center" justifyContent="space-between">
			<Typography component='h3' variant='h6'>{title}</Typography>
			<Button variant='contained' size='small' onClick={handleButtonClick} disabled={false}>{buttonTitle}</Button>
		</Stack>
	);
};
export default InspectionRoiPointListHead;