export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_PORT = process.env.REACT_APP_API_PORT;
export const API_URL = process.env.REACT_APP_API_URL;

export const API_PREFIX = '/api';

export const DOCTORS_ROOT = 'doctors';
export const PATIENTS_ROOT = 'subjects';
export const DATA_ROOT = 'data';
export const RAW_DATA_ROOT = 'raw-data';
export const SYNTHETIC_DATA_ROOT = 'synthetic-data';
export const MEDRECORD_ROOT = 'medrecords';
export const ROI_ROOT = 'rois';
export const PROTOCOLS_ROOT = 'protocols';
export const COMMENTS_ROOT = 'roi_comments';
export const PROCESSINGS_ROOT = 'processings';
export const PROFILE_ROOT = 'profile';


export const PROCESSING_STATES = {
	untouched:"untouched",
	convert:"convert",
	progress:"progress",
	failed:"failed",
	finished:"finished",
};
export const PROCESSING_STATES_LABELS = {
	untouched:"в очереди",
	convert:"конвертер",
	progress:"в процессе",
	failed:"ошибка",
	finished:"завершен",
};


export const PROCESSING_PERCENTS = {
	untouched:0,
	convert:25,
	progress:50,
	failed:100,
	finished:100,
};
