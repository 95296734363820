import React, { useEffect, useRef, useState} from 'react';

import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";

import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';

import useProcessingItem from "./useProcessingItem";

import {PROCESSING_PERCENTS, PROCESSING_STATES_LABELS} from "@api/constants";


const ProcessingItem = ({processing}) => {
	
	const intervalRef = useRef(null);
	
	const {status, modality, data_hash, synthetic_data, create_date, end_date } = processing;
	const { handleButtonDeleteClick, formattedDate, handleEyeClick } = useProcessingItem({processing});
	const [timer, setTimer] = useState(0);
	
	const getStatusColor = (status)=>{
		let color;
		switch (status) {
			case "finished":
				color = "success";
				break;
			case "failed":
				color = "error";
				break;
			default:
				color = "primary";
				break;
		}
		return color;
	};
	
	const millisToMinutesAndSeconds =  (millis) => {
		// 1- Convert to seconds:
		let seconds = (millis / 1000).toFixed(0);
		
		
		let days = parseInt( seconds / 86400 );
		seconds = seconds % 86400;
		
		// 2- Extract hours:
		let hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
		seconds = seconds % 3600; // seconds remaining after extracting hours
		// 3- Extract minutes:
		let minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
		// 4- Keep only seconds not extracted to minutes:
		seconds = seconds % 60;
		return days ? (days + "дн ") : "" +hours+"ч "+minutes+"м "+seconds + "c";
	};
	
	useEffect(() => {

		let diff;
		
		if (status === "finished" || status === "failed"){
			diff = (new Date(end_date)).getTime() - (new Date(create_date)).getTime();
		} else {
			diff = (new Date()).getTime() - (new Date(create_date)).getTime();
		}
		setTimer(diff);
		
		if (status !== "finished" && status !== "failed") {
			
			const intervalId = setInterval(() => {
				setTimer((prevTimer) => prevTimer + 1000)
			}, 1000)
			intervalRef.current = intervalId;
			
		}
		
		return () => {
			const intervalId = intervalRef.current;
			clearInterval(intervalId)
		};
	}, []);
	
	useEffect(() => {
		if (status === "finished" || status === "failed") {
			const intervalId = intervalRef.current;
			clearInterval(intervalId)
		}
	}, [status]);
	
	return (
		<ListItem
			secondaryAction={
				<Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
					{synthetic_data !== null && (
						<IconButton edge="end" aria-label="delete" onClick={handleEyeClick}>
							<VisibilityIcon />
						</IconButton>
					)}
					<IconButton edge="end" aria-label="delete" onClick={handleButtonDeleteClick}>
						<DeleteIcon />
					</IconButton>
				</Stack>
			}
		>
			<ListItemText primary={
					<Stack direction={'row'} justifyContent={'space-between'} >
						<Box sx={{display:'inline-block'}}>
							<Chip label={modality} size={'small'} /> - {formattedDate} ({millisToMinutesAndSeconds(timer)}) <Chip label={PROCESSING_STATES_LABELS[status]} color={getStatusColor(status)} size={'small'}/>
						</Box>
					</Stack>
				} secondary={
					<>
						<Typography variant="caption" display="block" gutterBottom>{`Hash: ${data_hash}`}</Typography>
						<LinearProgress variant="determinate" color={getStatusColor(status)} value={PROCESSING_PERCENTS[status]} />
					</>
				}
			/>
		</ListItem>
	);
};
export default ProcessingItem;