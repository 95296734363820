import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

import GLTFCanvasGrid from "./components/GLTFCanvasGrid/GLTFCanvasGrid";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import CircularProgressWithLabel from "@ui/CircularProgressWithLabel";
import InspectionLayout from "@ui/layout/InspectionLayout";

import useGLTFViewerPage from "./useGLTFViewer";
import useTranslate from "@hooks/useTranslate";

import LevaControls from "./components/LevaControls/LevaControls";

import InspectionProtocolStateRow from "../PatientSingle/Inspection/components/ProtocolStateRow/ProtocolStateRow";
import InspectionProtocolList from "../PatientSingle/Inspection/components/ProtocolList/ProtocolList";
import CommentsBlock from "../PatientSingle/Protocols/SingleProtocol/components/CommentsBlock/CommentsBlock";
import translates from "../PatientSingle/Inspection/translates";
import ROIPointGltfList from "@ROIPoint/GltfList/ROIPointGltfList";
import GltfRoiPointListHead from "./components/CanvasVolume/components/ROIPointListHead/ROIPointListHead";
import {buildUrl} from "@api/settings";
import {DIMS_NUM} from "./../../constants";
import ClustersList from "./components/ClustersList/ClustersList";
import syntheticDataGetRequest from "@api/synthetic-data/get";
import {editableProtocolSetRedux} from "@redux/protocol/actions";
import {activeIndexSetRedux, pointInfoSetRedux, pointPosSetRedux} from "@redux/gltf/actions";
import processingGetPointInfoRequest from "@api/processing/getPointInfo";
import roiGetRequest from "@api/roi/get";
import {roiPointActiveSetRedux} from "@redux/ROIPoint/actions";
import roiGetCommentsRequest from "@api/roi/getComments";
import {commentsSetRedux} from "@redux/comment/actions";


const getPointIndexByCoords = (xi,yi,zi)=> zi*(DIMS_NUM*DIMS_NUM) + yi*DIMS_NUM + xi;

const GLTFViewerPage = () => {
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const {dataId} = useParams();
	const params = useParams();
	
	const {protocolsTitle, backButtonTitle} = useTranslate({translates});
	
	const {roiPointActive} = useSelector((state) => state.ROIPoint);
	const {getArchive,archive, percent, message, isLoading} = useGLTFViewerPage();
	
	const { gltfReady, pointInfo } = useSelector((state) => state.gltf);
	
	const handleBackButtonClick = () => navigate(-1);
	const handleBackButton2Click = () => navigate("./../");
	
	const fn = async ()=>{
		
		console.log(dataId);
		
		const {isSuccess,data} = await syntheticDataGetRequest(dataId);
		
		if (!isSuccess) return;
		
		console.log(data);
		console.log(data.path.replace("_all","_gltf"));
		// const zipUrl = buildUrl('/uploads/raw_data/stack-single-full.zip');
		// const zipUrl = '/__data/stack-single-full.zip';
		// const zipUrl = '/__data/stack-single-half.zip';
		// const zipUrl = '/__data/30-single-full.zip';
		// const zipUrl = buildUrl("/synthetic_data/6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b/30_all_gltf.zip");
		// const zipUrl = '/__data/30_all_gltf.zip';
		// const zipUrl = '/__data/30_all_new.zip';
		
		
		const getGltfPath = ()=>{
			return data.path
				.replace("_all","_gltf")
				.replace("/api","")
		};
		const zipUrl = buildUrl(getGltfPath());
		
		// http://10.16.88.53:3002/api/synthetic_data/7a61b53701befdae0eeeffaecc73f14e20b537bb0f8b91ad7c2936dc63562b25/30.zip
		
		await getArchive(zipUrl)
		
	};
	const fn2 = async()=>{
		
		console.log("!!!!!")
		const protocolId = searchParams.get("protocol");
		const pointId = searchParams.get("point");
		console.log(protocolId)
		console.log(pointId)
		const resp = await roiGetRequest(pointId)
		console.log(resp)
		console.log(resp.data)
		console.log(resp.data.note)
		dispatch(roiPointActiveSetRedux(parseInt(resp.data.id)));
		const coords = JSON.parse(resp.data.note.split(';').at(0))
		console.log(coords)
		dispatch(editableProtocolSetRedux({protocolId:parseInt(protocolId)}));
		dispatch(activeIndexSetRedux({
			x:Math.ceil(coords.x) + (256/2),
			y:Math.ceil(coords.y) + (256/2),
			z:Math.ceil(coords.z) + (256/2),
		}));
		
		dispatch(pointPosSetRedux({
			x: Math.ceil(coords.x),
			y: Math.ceil(coords.y),
			z: Math.ceil(coords.z)
		}))
		
		const {data} = await roiGetCommentsRequest(resp.data.id);
		dispatch(commentsSetRedux(data))
		
		const _pointX = Math.ceil(coords.x);
		const _pointY = Math.ceil(coords.y);
		const _pointZ = Math.ceil(coords.z);
		const pointIndex = getPointIndexByCoords(_pointX + (256/2), _pointY + (256/2), _pointZ + (256/2));
		try {
			const {data} = await processingGetPointInfoRequest(1,pointIndex);
			
			dispatch(pointInfoSetRedux(data));
			
		} catch (e) {
			console.error(e)
		}

	}
	
	useEffect(() => {
		fn()
			.catch(error=>console.log(error))
		
		const protocolId = searchParams.get("protocol");
		const pointId = searchParams.get("point");
		console.log(protocolId)
		console.log(pointId)
		
		if (protocolId && pointId){
			fn2()
				.catch(error=>console.log(error))
		}
		
	}, []);

	return (
		<>
			{isLoading || archive===null ? (
				<Stack alignItems={'center'} justifyContent={'center'} sx={{position: "relative", height: '100vh'}}>
					<Stack alignItems={'center'}>
						<CircularProgressWithLabel value={percent*100} />
						{isLoading && <div style={{marginTop:"10px"}}>{message}</div>}
					</Stack>
				</Stack>
			):(
				<InspectionLayout>{{
					main: (
						<>
							<Box sx={{mt: 2, ml:2, position:'absolute', zIndex:10}}>
								<Button variant='contained' size='small' onClick={handleBackButtonClick} sx={{}}>{backButtonTitle}</Button>
								<Button variant='contained' size='small' onClick={handleBackButton2Click} sx={{ml:2}}>old visualizer</Button>
							</Box>
							<GLTFCanvasGrid _archive={archive}/>
							{gltfReady && <LevaControls/>}
						</>
					),
					aside: (
						<Box sx={{p: 2, boxSizing: "border-box"}}>
							{gltfReady && (
								<>
									{pointInfo !== null && (
										<>
											<Typography component='h3' variant='h6'>Информация о точке</Typography>
											{ Object.entries(pointInfo).map( ([key,value])=>
												<div key={key} style={{color:(key !== "index" && value>95)?"red":"default"}}>{key} : {value}</div>
											)}
											<Divider sx={{my: 2}}/>
										</>
									)}
									<ClustersList/>
									<Stack sx={{height: 300, width:'100%'}}>
										{/*<InspectionRoiPointListHead isArchiveLoading={false}/>*/}
										<GltfRoiPointListHead isArchiveLoading={false}/>
										<ROIPointGltfList sx={{width: '100%', bgcolor: 'background.paper', mb: 1}}/>
									</Stack>
									<InspectionProtocolStateRow/>
									<Divider sx={{my: 2}}/>
									<Typography component='h3' variant='h6'>{protocolsTitle}</Typography>
									
									<InspectionProtocolList/>
									<Divider sx={{my: 2}}/>
									{roiPointActive !==null && (<CommentsBlock/>)}
								</>
							)}
						</Box>
					)
				}}
				</InspectionLayout>
			)}
		</>
	);
};
export default GLTFViewerPage;
