import {pointPosAndActiveIndexSetRedux} from "@redux/gltf/actions";
import {useDispatch} from "react-redux";


const useVolume3D = () => {
	
	const dispatch = useDispatch();
	
	const onClick = async (event) => {
		
		event.stopPropagation();
		
		if (event.delta === 0){
			
			const point = event.intersections[0].point;
			
			const ceilX = Math.ceil(point.x);
			const ceilY = Math.ceil(point.y);
			const ceilZ = Math.ceil(point.z);
			
			await dispatch(pointPosAndActiveIndexSetRedux({
				pointPos:{ x:ceilX, y:ceilY, z:ceilZ },
				activeIndex:{ x:ceilX + (256/2), y:(256/2) + ceilY, z:ceilZ + (256/2) }
			}))
			
		}
	};
	
	return {
		onClick,
	};
};

export default useVolume3D;