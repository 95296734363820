import {buildUrl} from "../settings";
import axiosService from "../../libs/services/axiosService";
import {PATIENTS_ROOT} from "../constants";
import buildResponse from "../buildResponse";


const patientGetAllFilteredRequest = async (lastName) => {

	const result = await axiosService.get(buildUrl(`/${PATIENTS_ROOT}/filter?last_name=${lastName}`));
	
	return buildResponse(result);
};
export default patientGetAllFilteredRequest;