import React, { useEffect } from "react";
import { useSelector} from "react-redux";

import Box from "@mui/material/Box";

import Ruler from "./components/Ruler";
import VRuler from "./components/VRuler";


const GLTFCanvasWrapper = ({dim, children}) => {
	
	const { zoom } = useSelector((state) => state.gltf);
	
	return (
		
		<Box sx={{position:"relative"}}>
			{children}
			
			<Ruler zoom={zoom[dim.name]}/>
			<VRuler zoom={zoom[dim.name]}/>
			
			<div style={{position:"absolute", color:"white", bottom:10, right:10}}>
				{dim.name} ({dim.label})
			</div>
		
		</Box>

	);
};
export default GLTFCanvasWrapper;
