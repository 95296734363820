import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import List from "@mui/material/List";
import ListEmptyStateWrap from "./../../../ui/ListEmptyStateWrap/ListEmptyStateWrap";

import PatientListItem from "../Item/PatientListItem";
import usePatientMyList from "./usePatientMyList";


const PatientMyList = () => {
	const {patientMyList} = usePatientMyList();
	return (
		<ListEmptyStateWrap length={patientMyList.length} emptyText="">
			<List sx={{width: '100%', bgcolor: 'background.paper'}}>
				{patientMyList.map((patient:any) => (
					<PatientListItem patient={patient} key={uuidv4()}/>
				))}
			</List>
		</ListEmptyStateWrap>
	);
};
export default PatientMyList;