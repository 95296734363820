import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";

import routes from "./routes";
import Login from "./pages/Login";
import RegisterPage from "./pages/Register";
import RegisterSuccessPage from "./pages/RegisterSuccess/RegisterSuccess";
import PrivateRoute from "@components/PrivateRoute";
import HomePage from "./pages/Home/Home";
import ProfilePage from "./pages/Profile/Profile";
import PatientListPage from "./pages/PatientList/PatientList";
import MyPatientListPage from "./pages/MyPatientList/MyPatientList";
import LibraryPage from "./pages/Library";
import FavoritePage from "./pages/Favorite/Favorite";
import PatientCreateSuccessPage from "./pages/PatientCreateSuccess/PatientCreateSuccess";
import PatientSinglePage from "./pages/PatientSingle/PatientSingle";
import PatientSingleInspection from "./pages/PatientSingle/Inspection";
import PatientSingleProtocols from "./pages/PatientSingle/Protocols/Protocols";
import SingleProtocol from "./pages/PatientSingle/Protocols/SingleProtocol/SingleProtocol";
import Viewer from "./pages/Viewer";
import GLTFViewerPage from "./pages/GLTFViewer/GLTFViewer";


const AppRouter = () => {
	return (
		<Router>
			<Routes>
				<Route exact path={'/gltfviewer'} element={<GLTFViewerPage/>}/>
				<Route exact path={routes.login} element={<Login/>}/>
				<Route exact path={routes.register} element={<RegisterPage/>}/>
				<Route exact path={routes.registerSuccess} element={<RegisterSuccessPage/>}/>
				<Route exact path={routes.mainMenu} element={<PrivateRoute from={routes.mainMenu}><HomePage/></PrivateRoute>}/>
				<Route exact path={routes.profile} element={<ProfilePage/>}/>
				<Route exact path={routes.patientChoose} element={<PrivateRoute from={routes.patientChoose}><PatientListPage/></PrivateRoute>}/>
				<Route exact path={routes.myPatients} element={<PrivateRoute from={routes.myPatients}><MyPatientListPage/></PrivateRoute>}/>
				<Route exact path={routes.library} element={<PrivateRoute from={routes.library}><LibraryPage/></PrivateRoute>}/>
				<Route exact path={routes.favorite} element={<PrivateRoute from={routes.favorite}><FavoritePage/></PrivateRoute>}/>
				<Route exact path={routes.createPatientSuccess + "/:patientId"} element={<PrivateRoute from={routes.createPatientSuccess + "/:patientId"}><PatientCreateSuccessPage/></PrivateRoute>}/>
				<Route exact path={routes.patients + "/:patientId/"} element={<PrivateRoute from={routes.patients + "/:patientId/"}><PatientSinglePage/></PrivateRoute>}/>
				<Route exact path={routes.patients + "/:patientId/inspection/:dataId/processing/:processingId"} element={<PrivateRoute from={routes.patients + "/:patientId/inspection/:dataId/processing/:processingId"}><PatientSingleInspection/></PrivateRoute>}/>
				<Route exact path={routes.patients + "/:patientId/inspection/:dataId/processing/:processingId/gltf"} element={<PrivateRoute from={routes.patients + "/:patientId/inspection/:dataId/processing/:processingId/gltf"}><GLTFViewerPage/></PrivateRoute>}/>
				<Route exact path={routes.patients + "/:patientId/drafts"} element={<PrivateRoute from={routes.patients + "/:patientId/drafts"}><PatientSingleProtocols/></PrivateRoute>}/>
				<Route exact path={routes.patients + "/:patientId/drafts/:protocolId"} element={<PrivateRoute from={routes.patients + "/:patientId/drafts/:protocolId"}><SingleProtocol/></PrivateRoute>}/>
				
				<Route exact path="/visualizer" element={<Viewer/>}/>
				<Route exact path="*" element={<Navigate to="/"/>}/>
			</Routes>
		</Router>
	);
};
export default AppRouter;