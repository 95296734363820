import React, {} from "react";

import PageTitle from "@ui/PageTitle";
import DrawerLayout from "@ui/layout/DrawerLayout";

import PatientMyList from "@Patient/MyList/PatientMyList.tsx";
import PatientCreateModal from "@Patient/CreateModal/PatientCreateModal";

import useMyPatientListPage from "./useMyPatientList";
import MyPatientListPageSpeedDial from "./components/SpeedDial/MyPatientListPageSpeedDial";
import useTranslate from "@hooks/useTranslate";
import translates from "./translates";


const MyPatientListPage = () => {
	
	const {pageTitle} = useTranslate({translates});
	
	const { handleSpeedDialClick, setModalIsOpen, modalIsOpen} = useMyPatientListPage();
	
	return (
		<DrawerLayout>
			<PageTitle text={pageTitle}/>
			
			<PatientMyList/>
			<MyPatientListPageSpeedDial onClick={handleSpeedDialClick}/>
			<PatientCreateModal open={modalIsOpen} setOpen={setModalIsOpen}/>
		</DrawerLayout>
	);
};
export default MyPatientListPage;